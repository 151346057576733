import React from 'react'
import { Helmet } from 'react-helmet'
import { graphql, Link } from 'gatsby'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import Img from 'gatsby-image'

import Page from '../../components/Page'
import Schema from '../../components/Schema'
import HeaderInfo from './../../components/articles/HeaderInfo'
import ShareButtons from '../../components/articles/ShareButtons'
import Content from '../../components/articles/Content'
import Newsletter from '../../components/Newsletter'

export default function HiddenArticleTemplate({ data }) {
  const { article, site } = data
  const shareUrl = encodeURI(
    `${site.siteMetadata.siteUrl}${article.categoryLink}/${article.slug}`
  )

  const breadcrumbs = [
    {
      name: 'Editorial',
      uri: '/editorial',
    },
    {
      name: article.category,
      uri: article.categoryLink,
    },
    {
      name: article.title,
      uri: `${article.categoryLink}/${article.slug}`,
    },
  ]

  return (
    <Page className="p-article p-articleEditorial has-fingerPrints" datoIndex>
      <HelmetDatoCms seo={article.seoMetaTags} />
      <Schema breadcrumbs={breadcrumbs} />
      {article.canonicalUrl && (
        <Helmet
          link={[
            {
              rel: 'canonical',
              key: article.canonicalUrl,
              href: article.canonicalUrl,
            },
          ]}
        />
      )}

      <article id={article.id} className="c-article">
        <div className="container py-5">
          <header>
            <HeaderInfo
              date={article.pubDateTime}
              category={article.category}
              categoryLink={article.categoryLink}
              fullWidth={article.fullWidth}
            />
            <div className="row">
              <div
                className={
                  article.fullWidth
                    ? 'col-12 col-lg-12'
                    : 'col-12 col-lg-9 offset-lg-3'
                }
              >
                <h1 className="c-articleTitle">{article.title}</h1>
              </div>
            </div>
          </header>

          {/* <!-- Hero --> */}
          <section>
            {article.fullWidth ? (
              <div className="row">
                <div className="col-12 col-lg-11">
                  <Img
                    fluid={article.heroImage.fluid}
                    title={article.heroImage.title}
                    alt={article.heroImage.alt}
                    className="c-articleHeroImage img-fluid"
                  />
                </div>
                <div className="col-12 col-lg-1 mb-5 align-self-end">
                  <ShareButtons shareUrl={shareUrl} />
                </div>
              </div>
            ) : (
              <div className="row">
                <div className="col-12 col-lg-3 mb-2">
                  <ShareButtons shareUrl={shareUrl} />
                </div>
                <div className="col-12 col-lg-9">
                  <Img
                    fluid={article.heroImage.fluid}
                    title={article.heroImage.title}
                    alt={article.heroImage.alt}
                    loading="eager"
                    className="c-articleHeroImage img-fluid"
                  />
                </div>
              </div>
            )}
          </section>

          {/* <!-- Intro --> */}
          {article.leadIn && (
            <section>
              <div className="row">
                <div
                  className={
                    article.fullWidth
                      ? 'col-12 col-lg-12'
                      : 'col-12 col-lg-9 offset-lg-3'
                  }
                >
                  <div className="row">
                    <div className="col-12">
                      <h2 className="c-articleLeadIn">{article.leadIn}</h2>
                      <hr />
                    </div>
                  </div>
                </div>
              </div>
            </section>
          )}

          {/* <!-- Body --> */}
          <section>
            <div className="row">
              <div className="col-12">
                <div className="row">
                  <div
                    className={
                      article.fullWidth
                        ? 'col-12 col-lg-12'
                        : 'col-12 col-lg-9 offset-lg-3'
                    }
                  >
                    <Content content={article.content} />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </article>

      <Newsletter />
    </Page>
  )
}

export const pageQuery = graphql`
  query HiddenEditorialArticleQuery($slug: String!) {
    article: datoCmsHiddenArticle(slug: { eq: $slug }) {
      title
      slug
      canonicalUrl
      category
      categoryLink
      pubDateTime
      fullWidth
      heroImage {
        fluid(imgixParams: { w: "850", fm: "jpg", auto: "compress" }) {
          ...GatsbyDatoCmsFluid
        }
      }
      leadIn
      content {
        ...ArticleContentFragment
      }
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
    }
    site {
      siteMetadata {
        siteUrl
      }
    }
  }
`
